<template>
  <ModalEdit :editingItem="item" :onSubmit="onSubmit" :formSchema="formSchema" :modalName="modalName" />
</template>

<script>

import ModalEdit from "../../../components/crud/ModalEdit";
import {
  schemaBoolean,
  schemaNumber,
  schemaTextField
} from "../../../helpers/form";

export default {
  name: "EditFunil",
  components: {
    ModalEdit
  },
  props: [
    'item',
    'modalName',
    'onSubmit'
  ],
  data() {
    return {
      formSchema: {
        type: "object",
        properties: {
          titulo: schemaTextField('Título', true),
          descricao: schemaTextField('Descrição', true),
          ativo: schemaBoolean('Ativo', true, 4),
          filtra_carteira: schemaBoolean('Filtra carteira', true, 4),
          ordem: schemaNumber('Ordem de exibição', true, 4),
        },
        value: {}
      }
    }
  }
}
</script>
